<template>
    <v-container fluid class="mt-12">
        <v-row>
            <v-col cols="10" class="pt-0">
                <v-text-field outlined dense class="search-text-field" placeholder="Search" hide-details
                    prepend-inner-icon="mdi-magnify">
                </v-text-field>
            </v-col>
            <v-col cols="2">
                <v-spacer></v-spacer>
                <v-btn-toggle class="d-flex justify-end pr-1">
                    <v-btn dense large color="black" style="color: white" @click="eventOpenCreateNewLeadDialog()">
                        <v-icon color="white" class="mr-2">mdi-plus</v-icon>
                        <label class="text-proper-case" style="cursor: pointer;">Create Lead</label>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row class="mt-2">
            <v-col cols="12" class="pt-0">
                <v-card outlined class="card-format">
                    <v-data-table :headers="headers" :items="getLeads" :sort-by.sync="sort_by"
                        :sort-desc.sync="sort_desc">
                        <template v-slot:item="{ item }">
                            <tr @click="eventMoveToEdit(item)">
                                <td>
                                    {{ item.id }}
                                </td>
                                <td>
                                    {{ $date(item.created_at).format('MMM DD YYYY') }}
                                </td>
                                <td>
                                    {{ item.first_name }} {{ item.last_name }}
                                </td>
                                <td>
                                    {{ item.phone }}
                                </td>
                                <td>
                                    {{ item.email }}
                                </td>
                                <td>
                                    {{ item.start_date }}
                                </td>
                                <td>
                                    {{ item.people }}
                                </td>
                                <td>
                                    {{ item.event_type.name }}
                                </td>
                                <td>
                                    {{ item.location.name }}
                                </td>
                                <td>
                                    {{
                                    getAdvisorById(item.advisor_id) != null
                                    ? getAdvisorById(item.advisor_id).name
                                    : null
                                    }}
                                </td>
                                <td>
                                    <v-tooltip bottom color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on"><v-icon small
                                                    color="primary"
                                                    @click="eventMoveToEdit(item)">mdi-pen</v-icon></v-btn>
                                        </template>
                                        <span>Update a Lead</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="error">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on"
                                                @click.stop="eventOpenConfirmDeleteLeadDialog(item)">
                                                <v-icon small color="error">mdi-delete</v-icon></v-btn>
                                        </template>
                                        <span>Delete a Lead</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <CreateNewLead :show_create_new_lead_dialog="lead_components.show_create_new_lead_dialog"
            @eventCloseCreateNewLead="eventCloseCreateNewLead()"></CreateNewLead>

        <ConfirmDeleteLead :show_confirm_delete_lead_dialog="lead_components.show_confirm_delete_lead_dialog"
            :lead_id="lead_id" @eventCloseConfirmDeleteLeadDialog="eventCloseConfirmDeleteLeadDialog()">
        </ConfirmDeleteLead>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Leads from '../../modules/admin/leads/modules/leads.js'

// All Components added here
import CreateNewLead from "./components/CreateNewLead.vue";
import ConfirmDeleteLead from "./components/ConfirmDeleteLead.vue";

export default {
    data: () => ({
        headers: [
            { text: 'Leads ID', value: 'id', align: 'center' },
            { text: 'Created', value: 'created_at', align: 'center' },
            { text: 'Group Leader', value: 'group_leader', align: 'center' },
            { text: 'Mobile', value: 'phone' },
            { text: 'Email', value: 'email' },
            { text: 'Party Date', value: 'start_date' },
            { text: 'Size', value: 'people', },
            { text: 'Event Type', value: 'event_type.name' },
            { text: 'Destination', value: 'location.name' },
            { text: 'Advisor', value: 'advisor' },
            { text: 'Action', value: 'action' },
        ],
        sort_by: 'id',
        sort_desc: true,
        lead_components: {
            show_create_new_lead_dialog: false,
            show_confirm_delete_lead_dialog: false
        },
        classes: {
            lead: null
        },
        lead_id: null,
        freedom_bookings_file: null,
    }),
    components: {
        CreateNewLead,
        ConfirmDeleteLead
    },
    created() {
        this.setPageName('Leads')
        this.lead = new Leads()
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            getLeads: 'leadsStore/getLeadsGetter',
            get_selected_company: 'auth/get_selected_company',
            getAdvisorById: 'leadsStore/getAdvisorByIdGetter'
        })
    },
    async mounted() {

        // Leads Getting of data
        const leads_data = await this.lead.getLeads({ company_id: this.get_selected_company.id }).then((data) => data)

        if (typeof leads_data.status == 'undefined') {
            this.setLeads(leads_data)
        }

        // Getting of Advisors based on Company ID
        const advisor_data = await this.lead.getAdvisors({ company_id: this.get_selected_company.id }).then((data) => data)
        this.setAdvisors(typeof advisor_data.status == 'undefined' ? advisor_data : [])

        const location_data = await this.lead.getLocations({ company_id: this.get_selected_company.id })
        this.setLocation(typeof location_data.status == 'undefined' ? location_data : [])

    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
            setLeads: 'leadsStore/setLeadsAction',
            setAdvisors: 'leadsStore/setAdvisorsAction',
            setLocation: 'locationStore/setLocationAction'
        }),
        eventOpenCreateNewLeadDialog() {
            this.lead_components.show_create_new_lead_dialog = true
        },
        async eventCloseCreateNewLead() {
            const leads_data = await this.lead.getLeads({ company_id: this.get_selected_company.id }).then((data) => data)
            this.setLeads(typeof leads_data.status == "undefined" ? leads_data : [])
            this.lead_components.show_create_new_lead_dialog = false
        },
        eventOpenConfirmDeleteLeadDialog(item) {
            this.lead_id = item.id
            this.lead_components.show_confirm_delete_lead_dialog = true
        },
        async eventCloseConfirmDeleteLeadDialog() {
            const leads_data = await this.lead.getLeads({ company_id: this.get_selected_company.id }).then((data) => data)
            this.setLeads(typeof leads_data.status == "undefined" ? leads_data : [])
            this.lead_components.show_confirm_delete_lead_dialog = false
        },
        eventMoveToEdit(item) {
            this.$router.push({ name: 'EditLeads', params: { id: item.id } })
        },
        async importFreedomBookings() {
            if (this.freedom_bookings_file) {
                const formData = new FormData()
                formData.append('file', this.freedom_bookings_file)
                formData.append('company_id', this.get_selected_company.id)
                await this.$axios.post('admin/leads/import_leads', formData)
                    .then(({ data }) => {
                        console.log(data)
                        this.freedom_bookings_file = null
                    })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    border-radius: 15px !important;

    .v-data-footer {
        background-color: #E9E9E9;
        color: #7C7C7C;
        font-family: 'Work Sans';
    }

    .v-data-footer__select .v-text-field {
        background-color: white;

        .v-select__slot {
            padding-left: 10px;
        }
    }
}

.label-text-color {
    color: #7C7C7C;
}

.text-proper-case {
    text-transform: capitalize;
}

.search-text-field {
    margin-top: 15px;

    .v-input__slot {
        min-height: 0 !important;
        margin-top: 8px;
    }

    .v-input__prepend-inner {
        margin-top: 5px !important;
    }
}
</style>