<template>
    <v-container fluid class="mt-1">
        <v-card class="card-format my-5 px-10 py-8">
            <v-card-text>
                <v-row>
                    <v-col cols="6" class="pr-10" style="border-right:solid 1px lightgrey;">
                        <small class="custom-small">EVENT DETAILS</small><br><br>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="edit_event.name" outlined dense label="Event Name"
                                    type="text"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="edit_event.event_status" outlined dense label="Event Status"
                                    :items="['New', 'Pending', 'Active', 'Rush', 'Processing', 'Updated and Needs Action']"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="event.lead.start_date" label="Start Date" readonly dense
                                    outlined></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-dialog v-model="menu1" max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="edit_event.pay_date" label="Pay By Date" readonly
                                            v-bind="attrs" v-on="on" dense outlined></v-text-field>
                                    </template>
                                    <v-date-picker v-if="menu1" v-model="edit_event.pay_date" full-width
                                        @change="menu1 = false">
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="edit_event.deposit_amount" outlined dense
                                    label="Deposit Required" type="text"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-dialog v-model="menu2" max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="edit_event.deposit_due_date" label="Deposit Due Date"
                                            readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                                    </template>
                                    <v-date-picker v-if="menu2" v-model="edit_event.deposit_due_date" full-width
                                        @change="menu2 = false">
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="pl-10">
                        <small class="custom-small">EVENT SETTINGS</small><br><br>
                        <v-row>
                            <v-col cols="12">
                                <div style="display:flex;">
                                    <v-text-field v-model="edit_event.late_fee_amount" label="Amount" outlined dense
                                        class="mr-2"></v-text-field>
                                    <v-text-field v-model="edit_event.late_fee_days" label="Days" outlined dense
                                        class="mr-2"></v-text-field>
                                    <v-select v-model="edit_event.late_fee_date_type" label="Date type"
                                        :items="['Event Date', 'Pay By Date']" outlined dense></v-select>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="edit_event.admin_fee" outlined dense label="Admin Fee"
                                    type="text"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="edit_event.free_stag_hen"
                                    :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]" item-text="text"
                                    item-value="value" outlined dense label="Free Stag/Hen"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="edit_event.bar_crawl"
                                    :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]" item-text="text"
                                    item-value="value" outlined dense label="Bar Crawl"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="edit_event.is_deposit_paid"
                                    :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]" item-text="text"
                                    item-value="value" outlined dense label="Org Deposit Paid"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="edit_event.is_fully_paid"
                                    :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]" item-text="text"
                                    item-value="value" outlined dense label="Event Fully Paid"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="edit_event.supplier_paid"
                                    :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]" item-text="text"
                                    item-value="value" outlined dense label="Supplier Paid"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="edit_event.is_cancelled"
                                    :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]" item-text="text"
                                    item-value="value" outlined dense label="Cancelled Event"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="edit_event.disable_payment"
                                    :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]" item-text="text"
                                    item-value="value" outlined dense label="Disable Payment"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="edit_event.allow_org_deposit"
                                    :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]" item-text="text"
                                    item-value="value" outlined dense label="Allow Organizer Deposit"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="edit_event.disable_inv_deposit"
                                    :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]" item-text="text"
                                    item-value="value" outlined dense label="Disable Invitee Deposit"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="edit_event.cutoff_extension" outlined dense
                                    label="Invitee Deposit Cutoff Extension" type="text"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="edit_event.disable_payment_surcharge"
                                    :items="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]" item-text="text"
                                    item-value="value" outlined dense label="Disable Payment Surcharge"></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div style="text-align:right;">
                            <v-btn dark class="custom-buttons" @click="updateEvent()">Update</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Events from '../../modules/admin/events/modules/events.js'

export default {
    components: {
    },
    data: () => ({
        page_name: "Event",
        menu1: false,
        menu2: false,
        edit_event: {
            id: null,
            name: null,
            reference_code: null,
            password: null,
            pay_date: null,
            deposit_amount: null,
            deposit_due_date: null,
            is_deposit_paid: null,
            is_fully_paid: null,
            supplier_paid: null,
            is_cancelled: null,
            event_status: null,
            admin_fee: null,
            cutoff_extension: null,
            disable_payment: null,
            allow_org_deposit: null,
            disable_inv_deposit: null,
            free_stag_hen: null,
            bar_crawl: null,
            late_fee_amount: null,
            late_fee_days: null,
            late_fee_date_type: null,
            disable_payment_surcharge: 0
        },
        classes: {
            events: null,
        },
    }),
    created() {
        this.setPageName(this.page_name)
        this.classes.events = new Events()
    },
    async mounted() {
        this.setData()
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            event: 'eventsStore/getSelectedEventGetter',
        })
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
        }),
        setData() {
            this.edit_event.id = this.event.id
            this.edit_event.name = this.event.name
            this.edit_event.reference_code = this.event.reference_code
            this.edit_event.password = this.event.password
            this.edit_event.pay_date = this.event.pay_date
            this.edit_event.deposit_amount = this.event.deposit_amount
            this.edit_event.deposit_due_date = this.event.deposit_due_date
            this.edit_event.is_deposit_paid = this.event.is_deposit_paid
            this.edit_event.is_fully_paid = this.event.is_fully_paid
            this.edit_event.supplier_paid = this.event.supplier_paid
            this.edit_event.is_cancelled = this.event.is_cancelled
            this.edit_event.event_status = this.event.event_status
            this.edit_event.admin_fee = this.event.admin_fee
            this.edit_event.cutoff_extension = this.event.cutoff_extension
            this.edit_event.disable_payment = this.event.disable_payment
            this.edit_event.allow_org_deposit = this.event.allow_org_deposit
            this.edit_event.disable_inv_deposit = this.event.disable_inv_deposit
            this.edit_event.free_stag_hen = this.event.free_stag_hen
            this.edit_event.bar_crawl = this.event.bar_crawl
            this.edit_event.late_fee_amount = this.event.late_fee_amount
            this.edit_event.late_fee_days = this.event.late_fee_days
            this.edit_event.late_fee_date_type = this.event.late_fee_date_type
            this.edit_event.disable_payment_surcharge = this.event.disable_payment_surcharge
        },
        async updateEvent() {
            let payload = {
                company_id: this.get_selected_company.id,
                ...this.edit_event
            }
            const result = await this.classes.events.updateEvent(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.$emit('refresh')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    border-radius: 15px !important;

    .v-data-footer {
        background-color: #E9E9E9;
        color: #7C7C7C;
        font-family: 'Work Sans';
    }

    .v-data-footer__select .v-text-field {
        background-color: white;

        .v-select__slot {
            padding-left: 10px;
        }
    }
}

.custom-small {
    color: #7C7C7C;
    font-size: 12px;
    font-weight: 600;
}

:deep(.theme--light.v-data-table tbody td) {
    height: 80px;
    border: none;
}

.label-text-color {
    color: #7C7C7C;
}

.text-proper-case {
    text-transform: capitalize;
}

.search-text-field {
    margin-top: 15px;

    .v-input__slot {
        min-height: 0 !important;
        margin-top: 8px;
    }

    .v-input__prepend-inner {
        margin-top: 5px !important;
    }
}

:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

.paid_row {
    background-color: #FFF1F1 !important;
}


:deep(.custom-text-field) {
    legend {
        display: none;
    }

    fieldset {
        border-color: #D3D3D3 !important;
    }

    input {
        color: #101010;
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
</style>