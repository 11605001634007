<template>
  <v-container fluid>
    <div style="display:flex;">
      <v-btn rounded dark color="#525A68" class="pl-10 pr-10 mb-10" @click="$router.push({ name: '/create-supplier' })">
        Create Supplier
      </v-btn>
    </div>
    <v-card class="card-format">
      <v-card-title>
        <v-spacer></v-spacer>
        <!-- <v-btn rounded outlined class="pl-10 pr-10 mr-3">
          Bulk Import
        </v-btn> -->
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="5">
            <v-autocomplete dense outlined label="Supplier Type" prepend-inner-icon="mdi-view-list-outline"
              :items="get_supplier_type" item-text="name" item-value="id" v-model="supplier_type"
              @change="getSuppliers()" />
          </v-col>
          <v-col cols="5">
            <v-text-field dense outlined label="Supplier Name" prepend-inner-icon="mdi-account" v-model="supplier_name"
              v-on:keyup.enter="getSuppliers()">
            </v-text-field>
          </v-col>
          <v-col cols="2" style="text-align:right;">
            <v-btn @click="getSuppliers()" class="pa-5 mr-5" rounded color="primary">Search</v-btn>
            <v-btn @click="resetFilter()" outlined class="pa-5" rounded>Reset</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="searching" class="text-center">
        <strong>Searching...</strong>
        <br />
        <v-progress-circular :size="120" :width="10" color="primary" indeterminate></v-progress-circular>
      </v-card-text>
      <v-card-text v-else>
        <v-data-table :headers="headers_all" :items="suppliers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
          <!-- <template v-slot:top>
              <v-row>
                <v-col cols="3">
                  <v-switch v-model="show_archived" label="Show archived"
                    style="position:relative; bottom: 13px;"></v-switch>
                </v-col>
              </v-row>

            </template> -->
          <template v-slot:item="{ item }">
            <tr style="cursor:pointer;" @click="selectSupplier(item)">
              <td>
                <img v-if="item.image" :src="api_url + 'media/suppliers/images/' + item.image" width="100px" contain>
                <v-icon v-else size="100">mdi-image-off-outline</v-icon>
              </td>
              <td><b>{{ item.supplier_name }}</b></td>
              <td>
                <label>{{ item.email }}</label><br>
                <label>{{ item.contact_name }}</label>
              </td>
              <td>{{ item.type ? item.type.name : null }}</td>
              <td>
                <div style="min-height:50px;max-height:100px;overflow-x:hidden;overflow-y:auto;align-content: center;">
                  <label v-for="(supplier_location, index) in item.supplier_locations" :key="index"
                    class="location-label">
                    {{ supplier_location.location.name }}
                  </label>
                </div>
              </td>
              <td><b>{{ item.products.filter((x) => !x.archived).length }} services</b></td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-row class="mt-5" v-if="get_user.id == 1">
      <h4>Mystagit supplier import</h4>
      <v-col cols="12" style="display:flex;">
        <v-file-input dense outlined style="max-width:200px;" class="mb-10" v-model="suppliers_file"
          @change="importSuppliers()" label="Suppliers">
        </v-file-input>
        <v-file-input dense outlined style="max-width:200px;" class="mb-10" v-model="suppliers_file_delete"
          @change="deleteSuppliers()" label="Delete Suppliers">
        </v-file-input>
        <v-file-input dense outlined style="max-width:200px;" class="mb-10" v-model="supplier_locations_file"
          @change="importSupplierLocations()" label="Supplier Locations">
        </v-file-input>
        <v-file-input dense outlined style="max-width:200px;" class="mb-10" v-model="supplier_product_types_file"
          @change="importSupplierProductTypes()" label="Product Types">
        </v-file-input>
        <v-file-input dense outlined style="max-width:200px;" class="mb-10" v-model="services_file"
          @change="importServices()" label="Services">
        </v-file-input>
        <v-file-input dense outlined style="max-width:200px;" class="mb-10" v-model="accom_rates_file"
          @change="importAccommodationRates()" label="Accomm Rates">
        </v-file-input>
        <v-file-input dense outlined style="max-width:200px;" class="mb-10" v-model="fingerfood_transpo_file"
          @change="importFingerfoodTranspo()" label="Fingerfood & Transpo">
        </v-file-input>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
  components: {
  },
  props: [],
  data: () => ({
    suppliers_file: null,
    suppliers_file_delete: null,
    supplier_locations_file: null,
    supplier_product_types_file: null,
    services_file: null,
    accom_rates_file: null,
    fingerfood_transpo_file: null,
    freedom_accom_file: null,
    freedom_activity_file: null,
    suppliers: [],
    archived_suppliers: [],
    supplier_name: null,
    supplier_type: null,
    location: null,
    sortBy: 'supplier_name',
    sortDesc: false,
    searching: false,
    headers_all: [
      { text: 'Image' },
      { text: 'Supplier Name' },
      { text: 'Email' },
      { text: 'Type' },
      { text: 'Locations', width: '20%' },
      { text: 'Services' },
      { text: '' },
    ],
    api_url: null,
  }),
  async mounted() {
    this.getSuppliers()
    this.fetchLocations()
    await this.$store.dispatch('supplier/fetch_supplier_type')
    this.api_url = process.env.VUE_APP_API_URL
  },
  created() {
    this.setPageName('Suppliers')
  },
  computed: {
    ...mapGetters({
      get_user: 'auth/get_user',
      get_selected_company: 'auth/get_selected_company',
      get_supplier_type: 'supplier/get_supplier_type',
    }),
  },
  methods: {
    ...mapActions({
      setPageName: 'pageNameStore/setPageNameAction',
      setLocations: 'locationStore/setLocationAction',
    }),
    async fetchLocations() {
      await this.$axios.get(`admin/locations/get_locations/${this.get_selected_company.id}`)
        .then(({ data }) => {
          if (data.response) {
            this.setLocations(data.data)
          }
        })
    },
    async getSuppliers() {
      let tp = {
        company_id: this.get_selected_company.id,
        service_type_id: this.supplier_type,
        supplier_name: this.supplier_name,
      }
      this.searching = true
      await this.$axios.get(`admin/suppliers/get_suppliers/${this.get_selected_company.id}`, tp)
        .then(({ data }) => {
          if (data.response) {
            this.suppliers = data.data
            console.log(data.data)
            this.searching = false
          }
        });
    },
    resetFilter() {
      this.supplier_type = null
      this.supplier_name = null
      this.getSuppliers()
    },
    selectSupplier(item) {
      this.$store.dispatch('supplier/set_supplier_setup', item)
      this.$router.push({ name: '/supplier-setup' })
    },
    async importSuppliers() {
      if (this.suppliers_file) {
        const formData = new FormData()
        formData.append('file', this.suppliers_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('admin/suppliers/import_suppliers', formData)
          .then(({ data }) => {
            console.log(data)
            this.suppliers_file = null
          })
      }
    },
    async deleteSuppliers() {
      if (this.suppliers_file_delete) {
        const formData = new FormData()
        formData.append('file', this.suppliers_file_delete)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('admin/suppliers/delete_suppliers', formData)
          .then(({ data }) => {
            console.log(data)
            this.suppliers_file_delete = null
          })
      }
    },
    async importSupplierLocations() {
      if (this.supplier_locations_file) {
        const formData = new FormData()
        formData.append('file', this.supplier_locations_file)
        await this.$axios.post('admin/suppliers/import_supplier_locations', formData)
          .then(({ data }) => {
            console.log(data)
            this.supplier_locations_file = null
          })
      }
    },
    async importSupplierProductTypes() {
      if (this.supplier_product_types_file) {
        const formData = new FormData()
        formData.append('file', this.supplier_product_types_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('admin/suppliers/import_supplier_product_types', formData)
          .then(({ data }) => {
            console.log(data)
            this.supplier_product_types_file = null
          })
      }
    },
    async importServices() {
      if (this.services_file) {
        const formData = new FormData()
        formData.append('file', this.services_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('admin/suppliers/import_services', formData)
          .then(({ data }) => {
            console.log(data)
            this.services_file = null
          })
      }
    },
    async importAccommodationRates() {
      if (this.accom_rates_file) {
        const formData = new FormData()
        formData.append('file', this.accom_rates_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('admin/suppliers/import_accom_rates', formData)
          .then(({ data }) => {
            console.log(data)
            this.accom_rates_file = null
          })
      }
    },
    async importFingerfoodTranspo() {
      if (this.fingerfood_transpo_file) {
        const formData = new FormData()
        formData.append('file', this.fingerfood_transpo_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('admin/suppliers/import_fingerfood_transpo', formData)
          .then(({ data }) => {
            console.log(data)
            this.fingerfood_transpo_file = null
          })
      }
    },
    async freedomImportAccom() {
      if (this.freedom_accom_file) {
        const formData = new FormData()
        formData.append('file', this.freedom_accom_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('admin/suppliers/import_freedom_accom', formData)
          .then(({ data }) => {
            console.log(data)
            this.freedom_accom_file = null
          })
      }
    },
    async freedomImportActivity() {
      if (this.freedom_activity_file) {
        const formData = new FormData()
        formData.append('file', this.freedom_activity_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('admin/suppliers/import_freedom_activity', formData)
          .then(({ data }) => {
            console.log(data)
            this.freedom_activity_file = null
          })
      }
    },
    // async archive(id) {
    //   await this.$axios
    //     .delete(`api/suppliers/archive_supplier/${id}`)
    //     .then(({ data }) => {
    //       if (data.response) {
    //         this.getSuppliers()
    //       }
    //     })
    // },
    // async restore(id) {
    //   await this.$axios
    //     .patch(`api/suppliers/restore_supplier/${id}`)
    //     .then(({ data }) => {
    //       if (data.response) {
    //         this.getSuppliers()
    //       }
    //     })
    // },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.card-format {
  border-radius: 15px !important;

  .v-data-footer {
    background-color: #E9E9E9;
    color: #7C7C7C;
    font-family: 'Work Sans';
  }

  .v-data-footer__select .v-text-field {
    background-color: white;

    .v-select__slot {
      padding-left: 10px;
    }
  }
}

:deep(tbody tr:hover) {
  background-color: transparent !important;
}

:deep(.theme--light.v-data-table tbody td) {
  height: 80px;
  border: none;
}

.label-text-color {
  color: #7C7C7C;
}

.text-proper-case {
  text-transform: capitalize;
}

.search-text-field {
  margin-top: 15px;

  .v-input__slot {
    min-height: 0 !important;
    margin-top: 8px;
  }

  .v-input__prepend-inner {
    margin-top: 5px !important;
  }
}

:deep(.custom-buttons span) {
  text-transform: none;
  font-family: Manrope;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  box-shadow: none !important;
}

:deep(.custom-buttons button) {
  padding: 8px 7px 8px 7px !important;
  border-radius: 5px;
}

:deep(.v-input__slot::before) {
  border-style: none !important;
}

.label-1 {
  color: #7C7C7C;
  background-color: #F7F7F7;
  font-size: 11px;
  border: solid 1px #7C7C7C;
  border-radius: 4px;
  padding: 3px 5px;
}

.label-2 {
  color: #EE9E25;
  background-color: #FFF3E2;
  font-size: 11px;
  border: solid 1px #EE9E25;
  border-radius: 4px;
  padding: 3px 5px;
}

.label-3 {
  color: #669E53;
  background-color: #E6F3E2;
  font-size: 11px;
  border: solid 1px #669E53;
  border-radius: 4px;
  padding: 3px 5px;
}

.label-4 {
  color: #AA3939;
  background-color: #FFCFCF;
  font-size: 11px;
  border: solid 1px #AA3939;
  border-radius: 4px;
  padding: 3px 5px;
}

.location-label {
  color: #669E53;
  background-color: #E6F3E2;
  font-size: 12px;
  border: solid 1px #669E53;
  border-radius: 4px;
  padding: 3px 5px;
  margin-right: 5px;
}
</style>