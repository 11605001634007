<template>
    <v-container fluid class="mt-1">
        <v-row>
            <v-col cols="6">
                <small class="custom-small">STATUS LEGEND</small>
                <br>
                <label class="mr-2"
                    style="color:#3988AA;background-color:#ECF9FF;font-size:11px;border:solid 1px #3988AA;border-radius:4px;padding:3px 5px;">UNPAID</label>
                <label class="mr-2"
                    style="color:#AA3939;background-color:#FFCFCF;font-size:11px;border:solid 1px #AA3939;border-radius:4px;padding:3px 5px;">PAID</label>
                <label class="mr-2"
                    style="color:#679F54;background-color:#E5F2E1;font-size:11px;border:solid 1px #679F54;border-radius:4px;padding:3px 5px;">REFUND</label>
            </v-col>
            <v-col cols="6" class="custom-buttons" style="text-align:right;">
                <v-btn color="#F7F7F7" class="mr-5" @click="openAddInvitee()"><v-icon class="mr-1"
                        small>mdi-account-plus-outline</v-icon>Add Invitee</v-btn>
                <v-btn color="#F7F7F7" class="mr-5" @click="openAddGuests()"><v-icon class="mr-1"
                        small>mdi-account-plus-outline</v-icon>Add Guests</v-btn>
                <!-- <v-btn color="#F7F7F7" class="mr-5"><v-icon class="mr-1" small>mdi-credit-card-outline</v-icon>Pay an
                    Invitee</v-btn> -->
                <!-- <v-btn color="#F7F7F7" class="mr-5"><v-icon class="mr-1" small>mdi-credit-card-outline</v-icon>Pay
                    Guestlist</v-btn> -->
                <!-- <v-btn @click="openMarkInviteesAsPaidDialog()" color="#F7F7F7" class="mr-5"
                    :disabled="checked_invitees.length > 0 ? false : true">
                    <v-icon class="mr-1" small>mdi-check-circle-outline</v-icon>Mark as Paid
                </v-btn> -->
                <v-menu right bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mr-5" dark v-bind="attrs" v-on="on">
                            Actions<v-icon class="ml-1" small>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-if="checked_invitees.length > 0" @click="openDeleteInviteesDialog()">
                            <v-list-item-title style="cursor:pointer;">
                                Delete Invitees
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-else>
                            <v-list-item-title style="color:lightgrey;">
                                Delete Invitees
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <br>
                <div class="mr-5 mt-10" v-if="checked_invitees.length > 0">
                    {{ checked_invitees.length }} invitees selected
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <div style="display:flex;align-items:center;height:30px;">
                    <small class="custom-small">EVENT STATUS</small>
                    <small class="custom-small ml-5 mt-1" style="color:black;font-size:9px;"><v-icon size="10"
                            color="#25BCED" class="mb-1">mdi-circle</v-icon> PAID IN FULL</small>
                    <small class="custom-small ml-3 mt-1" style="color:black;font-size:9px;"><v-icon size="10"
                            color="#FAAF3A" class="mb-1">mdi-circle</v-icon> PAYMENT CLOSED</small>
                    <small class="custom-small ml-3 mt-1" style="color:black;font-size:9px;"><v-icon size="10"
                            color="#679F54" class="mb-1">mdi-circle</v-icon> SUPPLIER PAID</small>
                </div>
                <div style="background-color:#25BCED;color:white;text-align:center;font-size:10px;font-weight:700;padding:5px 0px;border-radius:6px;"
                    class="mt-5">
                    PAID IN FULL
                </div>
            </v-col>
            <v-col cols="4">
                <div style="height:30px;"></div>
                <div style="background-color:#FAAF3A;color:white;text-align:center;font-size:10px;font-weight:700;padding:5px 0px;border-radius:6px;"
                    class="mt-5">
                    PAYMENT CLOSED
                </div>
            </v-col>
            <v-col cols="4">
                <div style="height:30px;"></div>
                <div style="background-color:#679F54;color:white;text-align:center;font-size:10px;font-weight:700;padding:5px 0px;border-radius:6px;"
                    class="mt-5">
                    SUPPLIER PAID
                </div>
            </v-col>
        </v-row>
        <v-card class="card-format my-5 px-10 py-8">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <small class="custom-small">EVENT DATE</small><br>
                        <b style="color:#101010;font-size:16px;">{{ event.lead.start_date }}</b><br><br>

                        <small class="custom-small">EVENT CODE</small><br>
                        <b style="color:#101010;font-size:16px;">{{ event.reference_code }}</b><br><br>

                        <small class="custom-small">EVENT PASSWORD</small><br>
                        <b style="color:#101010;font-size:16px;">{{ event.password }}</b><br><br>

                        <small class="custom-small">PAID TO DATE</small><br>
                        <b style="color:#101010;font-size:16px;">
                            {{ get_selected_company.currency }}{{ paidToDate.toFixed(2) }}
                        </b>
                        <br><br>

                        <small class="custom-small">REFUND TO DATE</small><br>
                        <b style="color:#101010;font-size:16px;">{{ get_selected_company.currency }}0</b><br><br>

                        <small class="custom-small">TOTAL AMOUNT PAID</small><br>
                        <b style="color:#101010;font-size:16px;">
                            {{ get_selected_company.currency }}{{ paidToDate.toFixed(2) }}
                        </b><br><br>
                    </v-col>
                    <v-col cols="3" style="border-right:solid 1px lightgrey;padding-right:80px;">
                        <small class="custom-small">ORGANISER</small><br>
                        <b style="color:#101010;font-size:16px;">{{ event.lead.first_name + ' ' + event.lead.last_name
                            }}</b><br><br>

                        <small class="custom-small">CREATED BY</small><br>
                        <b style="color:#101010;font-size:16px;">{{ event.creator.name ?? null }}</b><br><br>

                        <small class="custom-small">NOTES</small><br>
                        <b style="color:#101010;font-size:16px;">
                            <a @click="openNotesDialog()">View Event Notes</a>
                        </b>
                        <br><br>

                        <small class="custom-small">INVITEES</small><br>
                        <b style="color:#101010;font-size:16px;">
                            {{ event.invitees.length + ' out of ' + event.lead.people }}
                        </b>
                        <br><br>
                        <i class="custom-small">
                            Last Updated: <br>{{ $date(event.updated_at).format('MMMM DD, YYYY h:mm A') }}
                        </i>
                        <v-btn class="custom-buttons mt-5" dark @click="copyLinkV2()">Copy Sign Up Link</v-btn>
                        <br><br>
                        <!-- <v-text-field v-model="sign_up_link" readonly outlined dense></v-text-field> -->
                    </v-col>
                    <!-- <v-col cols="4" style="border-right:solid 1px lightgrey;padding:0px 100px;">
                        <small class="custom-small">EXTRA CHARGES SUMMARY</small><br><br>
                        <div
                            style="color:#101010;display:flex;justify-content:space-between;font-size:16px;border-bottom:solid 1px lightgrey;padding-bottom:10px;">
                            <div>Phone</div><b>{{get_selected_company.currency}}0</b>
                        </div><br>
                        <div
                            style="color:#101010;display:flex;justify-content:space-between;font-size:16px;border-bottom:solid 1px lightgrey;padding-bottom:10px;">
                            <div>Surcharge</div><b>{{get_selected_company.currency}}0</b>
                        </div><br>
                        <div style="color:#101010;display:flex;justify-content:space-between;font-size:16px;">
                            <div>Total</div><b style="color:#3988AA!important;">{{get_selected_company.currency}}0</b>
                        </div><br><br>
                        <div style="color:#101010;display:flex;justify-content:space-between;font-size:16px;">
                            <div>Extra Charge Fee</div><v-btn color="#679F54" dark class="custom-buttons">Add</v-btn>
                        </div><br>
                    </v-col> -->
                    <v-col cols="6" style="padding-left:100px;">
                        <small class="custom-small">PACKAGE SUMMARY</small><br><br>
                        <div v-for="(quote, index) in event.quotes" :key="index"
                            style="color:#101010;display:flex;justify-content:space-between;font-size:16px;border-bottom:solid 1px lightgrey;padding-bottom:10px;">
                            <div>{{ quote.name }}
                                <label style="color:grey;margin-left:5px;">
                                    [{{ event.invitees.filter((val) => val.quote_id == quote.id).length }}]
                                </label>
                            </div>
                            <b>{{ get_selected_company.currency }}{{ quote.total }}</b>
                        </div><br>
                        <div style="color:#101010;display:flex;justify-content:space-between;font-size:16px;">
                            <div>Contributions</div><b>{{ get_selected_company.currency }}0</b>
                        </div><br>
                        <div
                            style="color:#101010;display:flex;justify-content:space-between;font-size:16px;padding-bottom:10px;">
                            <div>Late Fee</div><b>{{ get_selected_company.currency }}{{ event.late_fee }}</b>
                        </div><br>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-row class="mt-15">
            <v-col cols="12" class="pt-0">
                <div style="display:flex;">
                    <v-text-field label="Search within the table" prepend-inner-icon="mdi-magnify" filled outlined dense
                        style="max-width:400px;" v-model="search" class="mr-4"></v-text-field>
                    <v-spacer></v-spacer>
                    <!-- <v-btn class="custom-buttons mr-3"><v-icon class="mr-2">mdi-filter-outline</v-icon>Filter</v-btn> -->
                    <!-- <v-btn class="custom-buttons mr-3"><v-icon class="mr-2">mdi-refresh</v-icon>Reset</v-btn> -->
                    <!-- <v-btn class="custom-buttons" dark><v-icon class="mr-2">mdi-tray-arrow-down</v-icon>Export</v-btn> -->
                </div>
                <v-card outlined class="card-format">
                    <v-data-table :headers="headers" :items="event.invitees" :search="search">
                        <template v-slot:header.checkbox="{}">
                            <input v-if="event.invitees.length > 0" type="checkbox" :checked="checked_all_invitees"
                                @click="checkAllInvitees()" style="cursor:pointer;">
                        </template>
                        <template slot="no-data">
                        </template>
                        <template v-slot:body.prepend>
                            <!-- <tr style="font-weight:600;cursor:initial;background-color: #EFFAFF;"
                                :set1="org_deposit_payment = event.lead.payments.filter((item) => item.status == 'Organizer Deposit')[0]"
                                :set2="org_package_payment = event.lead.payments.filter((item) => item.status == 'Organizer Payment')[0]"
                                :class="event.lead.payments.filter((item) => item.status == 'Organizer Payment')[0] ? 'paid_row' : 'unpaid_row'"> -->
                            <tr style="font-weight:600;cursor:initial;background-color: #EFFAFF;"
                                :set1="full_payment = event.lead.payments.filter((x) => x.payment_collection != null && x.payment_details.filter((y) => y.payment_type_id == 1).length > 0)"
                                :set2="deposit_payment = event.lead.payments.filter((x) => x.payment_collection != null && x.payment_details.filter((y) => y.payment_type_id == 2).length > 0)"
                                :class="event.lead.payments.filter((x) => x.payment_collection != null && x.payment_details.filter((y) => y.payment_type_id == 1).length > 0)[0] ? 'paid_row' : 'unpaid_row'">
                                <td>
                                    <!-- <input type="checkbox"
                                        :checked="checked_invitees.findIndex((items) => items.id == item.id) > -1 ? true : false"
                                        @click="checkInvitee(item)" style="cursor:pointer;"> -->
                                </td>
                                <td>
                                    {{ event.lead.first_name + ' ' + event.lead.last_name }}
                                    <br>
                                    <small style="color:#3988AA;">Organiser: {{ event.lead.id }}</small>
                                </td>
                                <td>
                                    {{ event.lead.email }}
                                    <br>
                                    <small style="color:#3988AA;">{{ event.lead.mobile }}</small>
                                </td>
                                <td style="text-align:center;">
                                    <label>
                                        {{ get_selected_company.currency }}{{ event.lead.payments.filter(x =>
                                            x.payment_collection != null).reduce((total,
                                                payment) => total + payment.total_paid_amount, 0) }}
                                    </label>
                                    <br>
                                    <v-btn class="custom-buttons" text small
                                        @click="openViewPaymentsDialog(event.lead)">View</v-btn>
                                </td>
                                <td>
                                    {{ full_payment[0] ? 'Fully Paid' : deposit_payment[0] ? 'Paid Deposit' :
                                        'Unpaid' }}
                                </td>
                                <td>
                                    <div v-if="full_payment[0]">
                                        {{ full_payment[0].payment_details.filter((x) => x.payment_type_id ==
                                            1)[0].quote ? full_payment[0].payment_details.filter((x) => x.payment_type_id ==
                                                1)[0].quote.name : 'None' }}
                                    </div>
                                </td>
                                <!-- <td>{{ event.lead.paid_by }}</td> -->
                                <td style="text-align:right;">
                                    <v-tooltip bottom color="primary" v-if="!full_payment[0]">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on"
                                                @click="openPayOrganiserDialog()">
                                                <v-icon color="black">mdi-credit-card-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add a Payment</span>
                                    </v-tooltip>

                                    <v-menu right bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on">
                                                <v-icon color="black">mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item style="cursor:pointer;" @click="sendOrganiserEmail()">
                                                <v-list-item-title>
                                                    Send Organiser Email
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>

                        <template v-slot:item="{ item }">
                            <tr style="font-weight:600;cursor:initial;background-color: #EFFAFF;"
                                :set1="full_payment = item.payments.filter((x) => x.payment_collection != null && x.payment_details.filter((y) => y.payment_type_id == 1).length > 0)"
                                :set2="deposit_payment = item.payments.filter((x) => x.payment_collection != null && x.payment_details.filter((y) => y.payment_type_id == 2).length > 0)"
                                :class="item.payments.filter((x) => x.payment_collection != null && x.payment_details.filter((y) => y.payment_type_id == 1).length > 0)[0] ? 'paid_row' : 'unpaid_row'">
                                <td>
                                    <!-- <input v-if="!inv_package_payment" type="checkbox"
                                        :checked="checked_invitees.findIndex((items) => items.id == item.id) > -1 ? true : false"
                                        @click="checkInvitee(item)" style="cursor:pointer;"> -->
                                </td>
                                <td>
                                    {{ item.name }}<br><small style="color:#3988AA;">Invitee: {{ item.id }}</small>
                                </td>
                                <td>
                                    {{ item.email }}<br><small style="color:#3988AA;">{{ item.mobile }}</small>
                                </td>
                                <td style="text-align:center;">
                                    <label>
                                        {{ get_selected_company.currency }}{{ item.payments.filter(x =>
                                            x.payment_collection != null).reduce((total, payment) =>
                                                total +
                                        payment.total_paid_amount, 0) }}
                                    </label>
                                    <br>
                                    <v-btn class="custom-buttons" text small
                                        @click="openViewPaymentsDialog(item)">View</v-btn>
                                </td>
                                <td>
                                    {{ full_payment[0] ? 'Fully Paid' : deposit_payment[0] ? 'Paid Deposit' :
                                        'Unpaid' }}
                                </td>
                                <td>
                                    <div v-if="full_payment[0]">
                                        {{ full_payment[0].payment_details.filter((x) => x.payment_type_id ==
                                            1)[0].quote.name }}
                                    </div>
                                </td>
                                <!-- <td>{{ item.paid_by }}</td> -->
                                <td style="text-align:right;">
                                    <v-tooltip bottom color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on"
                                                @click="openMarkInviteeAsPaidDialog(item)">
                                                <v-icon color="black">mdi-credit-card-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add a Payment</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on"
                                                @click="openUpdateInviteeDialog(item)">
                                                <v-icon color="black">mdi-pencil-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Update Invitee</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="primary" v-if="item.payments.length == 0">

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on"
                                                @click="openDeleteInviteeDialog(item)">
                                                <v-icon color="black">mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Invitee</span>
                                    </v-tooltip>
                                    <v-menu right bottom>

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small dense icon v-bind="attrs" v-on="on">
                                                <v-icon color="black">mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item style="cursor:pointer;" @click="sendInviteeEmail(item)">
                                                <v-list-item-title>
                                                    Send Invitee Email
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-dialog v-model="notes_dialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        Add Notes
                    </v-card-title>
                    <v-card-text>
                        <v-textarea v-model="notes" outlined></v-textarea>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeNotesDialog()">Cancel</v-btn>
                        <v-btn dark @click="addNotes()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-dialog v-model="add_invitee_dialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        Add Invitee
                    </v-card-title>
                    <v-card-text>
                        <div style="display:flex;justify-content:space-between;">
                            <b>Name</b><v-text-field label="Name" style="max-width:75%;" dense outlined
                                v-model="new_invitee.name"></v-text-field>
                        </div>
                        <div style="display:flex;justify-content:space-between;">
                            <b>Email</b><v-text-field label="Email" style="max-width:75%;" dense outlined
                                v-model="new_invitee.email"></v-text-field>
                        </div>
                        <div style="display:flex;justify-content:space-between;">
                            <b>Mobile</b><v-text-field label="Mobile" style="max-width:75%;" dense outlined
                                v-model="new_invitee.mobile"></v-text-field>
                        </div>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeAddInvitee()">Cancel</v-btn>
                        <v-btn dark @click="addInvitee()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-dialog v-model="update_invitee_dialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        Update Invitee
                    </v-card-title>
                    <v-card-text>
                        <div style="display:flex;justify-content:space-between;">
                            <b>Name</b><v-text-field label="Name" style="max-width:75%;" dense outlined
                                v-model="invitee_to_update.name"></v-text-field>
                        </div>
                        <div style="display:flex;justify-content:space-between;">
                            <b>Email</b><v-text-field label="Email" style="max-width:75%;" dense outlined
                                v-model="invitee_to_update.email"></v-text-field>
                        </div>
                        <div style="display:flex;justify-content:space-between;">
                            <b>Mobile</b><v-text-field label="Mobile" style="max-width:75%;" dense outlined
                                v-model="invitee_to_update.mobile"></v-text-field>
                        </div>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeUpdateInviteeDialog()">Cancel</v-btn>
                        <v-btn dark @click="updateInvitee()">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-dialog v-model="add_guests_dialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        Add Invitee
                    </v-card-title>
                    <v-card-text>
                        <div style="display:flex;justify-content:space-between;">
                            <b>Guests *</b><v-text-field label="Number of Guests *" type="number" style="max-width:75%;"
                                dense outlined v-model="guestlist.number"></v-text-field>
                        </div>
                        <div style="display:flex;justify-content:space-between;">
                            <b>Package *</b><v-select label="Package *"
                                :items="[{ name: 'Unpaid Guest', id: 0 }, ...event.quotes]" :item-text="'name'"
                                :item-value="'id'" style="max-width:75%;" dense outlined
                                v-model="guestlist.quote"></v-select>
                        </div>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeAddGuests()">Cancel</v-btn>
                        <v-btn dark @click="addGuests()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row v-if="invitee_to_delete">
            <v-dialog v-model="delete_invitee_dialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        Delete Invitee
                    </v-card-title>
                    <v-card-text>
                        Are you sure you want to delete {{ invitee_to_delete.name }}?
                        <v-text-field v-model="confirmation"
                            placeholder="Please type 'DELETE' to confirm"></v-text-field>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeDeleteInviteeDialog()">Cancel</v-btn>
                        <v-btn :dark="confirmation == 'DELETE'" @click="deleteInvitee()"
                            :disabled="confirmation != 'DELETE'">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row v-if="invitees_to_delete">
            <v-dialog v-model="delete_invitees_dialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        Delete Invitee(s)
                    </v-card-title>
                    <v-card-text>
                        Are you sure you want to delete the following invitees?
                        <label v-for="(invitee, index) in invitees_to_delete" :key="index"><br>{{ invitee.name
                            }}</label>
                        <v-text-field v-model="confirmation"
                            placeholder="Please type 'DELETE' to confirm"></v-text-field>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeDeleteInviteesDialog()">Cancel</v-btn>
                        <v-btn :dark="confirmation == 'DELETE'" @click="deleteInvitees()"
                            :disabled="confirmation != 'DELETE'">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- <v-row>
            <v-dialog v-model="mark_invitees_as_paid_dialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        Mark Invitees as Paid
                    </v-card-title>
                    <v-card-text>
                        <label v-for="(invitee, index) in checked_invitees" :key="index">{{ invitee.name }}<br></label>
                        <br>
                        <div style="display:flex;justify-content:space-between;">
                            <b>Package *</b><v-select label="Package *" :items="event.quotes" :item-text="'name'"
                                :item-value="'id'" style="max-width:75%;" dense outlined v-model="selected_quote"
                                return-object></v-select>
                        </div>
                        <v-text-field v-model="reference_code" label="Reference Code *" dense outlined></v-text-field>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeMarkInviteesAsPaidDialog()">Cancel</v-btn>
                        <v-btn dark @click="markInviteesAsPaid()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row> -->
        <v-row>
            <v-dialog v-model="mark_invitee_as_paid_dialog" max-width="500px" persistent>
                <v-card v-if="selected_invitee">
                    <v-card-title>
                        Add a Payment for {{ selected_invitee.name }}
                    </v-card-title>
                    <v-card-text>
                        <v-select label="Package *" :items="[{ name: 'None', value: 0 }, ...event.quotes]"
                            :item-text="'name'" :item-value="'id'" dense outlined v-model="selected_quote"
                            return-object></v-select>
                        <v-select v-model="selected_payment_type" :items="payment_types" :item-text="'type'"
                            :item-value="'id'" label="Payment Type *" dense outlined return-object></v-select>
                        <v-text-field v-model="custom_amount" type="number" label="Amount *" dense
                            outlined></v-text-field>
                        <v-text-field v-model="reference_code" label="Reference Code *" dense outlined></v-text-field>
                        <v-select v-model="selected_payment_gateway" :items="['Stripe', 'Sagepay']" return-object
                            label="Payment Gateway *" dense outlined></v-select>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeMarkInviteeAsPaidDialog()">Cancel</v-btn>
                        <v-btn dark @click="markInviteeAsPaid()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-dialog v-model="pay_organiser_dialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        Add a Payment for {{ event.lead.first_name }} {{ event.lead.last_name }}
                    </v-card-title>
                    <v-card-text>
                        <v-select label="Package *" :items="[{ name: 'None', value: 0 }, ...event.quotes]"
                            :item-text="'name'" :item-value="'id'" dense outlined v-model="selected_quote"
                            return-object></v-select>
                        <v-select v-model="selected_payment_type" :items="payment_types" :item-text="'type'"
                            :item-value="'id'" label="Payment Type *" dense outlined return-object></v-select>
                        <v-text-field v-model="custom_amount" type="number" label="Amount *" dense
                            outlined></v-text-field>
                        <v-text-field v-model="reference_code" label="Reference Code *" dense outlined></v-text-field>
                        <v-select v-model="selected_payment_gateway" :items="['Stripe', 'Sagepay']" return-object
                            label="Payment Gateway *" dense outlined></v-select>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closePayOrganiserDialog()">Cancel</v-btn>
                        <v-btn dark @click="markOrganiserAsPaid()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-dialog v-model="view_payments_dialog" max-width="700px" persistent>
                <v-card v-if="payments_to_view">
                    <v-card-text
                        v-for="(payment, payment_index) in payments_to_view.filter(x => x.payment_collection != null)"
                        :key="payment_index">
                        <v-data-table :headers="payments_headers" :items="payment.payment_details" disable-pagination
                            hide-default-footer :hide-default-header="payment_index != 0" scrollable>
                            <template v-slot:item="{ item, index }">
                                <tr :class="item.payment_type_id < 3 ? 'payment-type' : 'small-row'">
                                    <td>{{ index == 0 ? item.payment_id : null }}</td>
                                    <td>{{ item.payment_type.type }}</td>
                                    <td>{{ get_selected_company.currency + item.amount.toFixed(2) }}</td>
                                    <td>{{ payment.payment_date }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions class="custom-buttons">
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeViewPaymentsDialog()">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Leads from '../../modules/admin/leads/modules/leads.js'
import Events from '../../modules/admin/events/modules/events.js'
import Invitees from '../../modules/admin/invitees/modules/invitees.js'

export default {
    components: {
    },
    data: () => ({
        app_url: null,
        encrypted_event_id: null,
        page_name: "Invitees",
        headers: [
            { text: '', width: '5%', sortable: false, value: 'checkbox' },
            { text: 'Name', sortable: false, value: 'name' },
            { text: 'Email & Number', sortable: false, value: 'email' },
            { text: 'Amount Paid', sortable: false },
            // { text: 'Deposit Paid Date', sortable: false },
            // { text: 'Package Paid', sortable: false },
            // { text: 'Package Paid Date', sortable: false },
            // { text: 'Contribution', sortable: false },
            // { text: 'Payment Type', sortable: false },
            { text: 'Payment Status', sortable: false },
            { text: 'Package Purchase', sortable: false },
            // { text: 'Role Paid', sortable: false },
            { text: 'Action', width: '12%', sortable: false },
        ],
        payments_headers: [
            { text: 'ID', sortable: false, value: 'payment_id' },
            { text: 'Payment Type', sortable: false, value: 'payment_type.type' },
            { text: 'Amount', sortable: false, value: 'amount' },
            { text: 'Payment Date', sortable: false, value: 'payment_date' },
        ],
        payment_types: [],
        menu: false,
        payments_to_add: {
            amount: null,
            payment_type_id: null,
            payment_date: null,
            paid_to: null,
            quote_id: null,
        },
        payments_to_view: [],
        view_payments_dialog: false,
        search: null,
        classes: {
            leads: null,
            events: null,
            invitees: null,
        },
        events: [],
        checked_invitees: [],
        checked_all_invitees: false,
        notes_dialog: false,
        notes: null,
        add_invitee_dialog: false,
        new_invitee: {
            name: null,
            email: null,
            mobile: null,
        },
        add_guests_dialog: false,
        guestlist: {
            number: 0,
            quote: null,
        },
        delete_invitee_dialog: false,
        invitee_to_delete: null,
        delete_invitees_dialog: false,
        invitees_to_delete: [],
        selected_invitee: null,
        mark_invitee_as_paid_dialog: false,
        mark_invitees_as_paid_dialog: false,
        selected_quote: null,
        selected_payment_type: null,
        selected_payment_gateway: null,
        update_invitee_dialog: false,
        invitee_to_update: {
            id: null,
            name: null,
            email: null,
            mobile: null,
        },
        sign_up_link: null,
        confirmation: null,
        pay_organiser_dialog: false,
        organiser_paid_type: null,
        custom_amount: 0,
        reference_code: null,
    }),
    created() {
        this.setPageName(this.page_name)
        this.classes.leads = new Leads()
        this.classes.events = new Events()
        this.classes.invitees = new Invitees()
    },
    async mounted() {
        this.setData()
        this.getPaymentTypes()
        this.app_url = process.env.VUE_APP_CLIENT_URL
        this.encrypted_event_id = window.btoa(event.id)
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            event: 'eventsStore/getSelectedEventGetter',
        }),
        paidToDate() {
            let paid_to_date = 0
            let lead_total = this.event.lead.payments.filter(x => x.payment_collection != null).reduce((a, b) => a + b.total_paid_amount, 0)
            let invitee_total = this.event.invitees.reduce((a, b) => a + b.payments.filter(x => x.payment_collection != null).reduce((c, d) => c + d.total_paid_amount, 0), 0)
            paid_to_date = invitee_total + lead_total
            return paid_to_date
        },
    },
    methods: {
        ...mapActions({
            setPageName: 'pageNameStore/setPageNameAction',
        }),
        async getPaymentTypes() {
            await this.$axios.get('admin/payments/get_payment_types')
                .then(({ data }) => {
                    if (data.response) {
                        this.payment_types = data.data
                    }
                })
        },
        setData() {
            this.notes = this.event.notes
            this.sign_up_link = process.env.VUE_APP_CLIENT_URL + 'sign_up/' + this.event.encrypted_id
        },
        openViewPaymentsDialog(item) {
            console.log(item)
            item.payments.forEach(payment => {
                this.payments_to_view.push(payment)
            })
            this.view_payments_dialog = true
        },
        closeViewPaymentsDialog() {
            this.payments_to_view = []
            this.view_payments_dialog = false
        },
        openPayOrganiserDialog() {
            this.pay_organiser_dialog = true
        },
        closePayOrganiserDialog() {
            this.pay_organiser_dialog = false
            this.selected_quote = null
            this.selected_payment_type = null
            this.selected_payment_gateway = null
            this.organiser_paid_type = null
            this.custom_amount = 0
            this.reference_code = null
        },
        async markOrganiserAsPaid() {
            if (this.selected_quote == null || this.reference_code == null || this.custom_amount == 0 || this.selected_payment_type == null || this.selected_payment_gateway == null) {
                this.$toast.error('Please fill out all fields')
                return
            }

            let payload = {
                event_id: this.event.id,
                company_id: this.get_selected_company.id,
                total_paid_amount: this.custom_amount,
                payment_date: this.$date().format('YYYY-MM-DD hh:mm:ss'),
                reference_code: this.reference_code,
                event_name: this.event.name,
                quote_id: this.selected_quote.id ?? 0,
                paid_by: this.event.lead.id,
                payment_type_id: this.selected_payment_type.id,
                payment_gateway: this.selected_payment_gateway,
            }
            console.log(payload)
            const result = await this.classes.leads.markLeadAsPaid(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.closePayOrganiserDialog()
                this.$emit('refresh')
            }
            else {
                this.$toast.error(result.data.message)
            }
        },
        addPayment() {
            this.payments_to_add.push({
                amount: null,
                payment_type_id: null,
                payment_date: null,
                paid_to: null,
                quote_id: null,
            })
        },
        openMarkInviteeAsPaidDialog(item) {
            this.mark_invitee_as_paid_dialog = true
            this.selected_invitee = item
            console.log(item)
        },
        closeMarkInviteeAsPaidDialog() {
            this.mark_invitee_as_paid_dialog = false
            this.selected_invitee = null
            this.selected_quote = null
            this.reference_code == null
            this.payments_to_add = {
                amount: null,
                payment_type_id: null,
                payment_date: null,
                paid_to: null,
                quote_id: null,
            }
        },
        async markInviteeAsPaid() {
            if (this.selected_quote == null || this.reference_code == null) {
                this.$toast.error('Please fill out all fields')
                return
            }
            let payload = {
                event_id: this.event.id,
                company_id: this.get_selected_company.id,
                total_paid_amount: this.custom_amount,
                payment_date: this.$date().format('YYYY-MM-DD hh:mm:ss'),
                reference_code: this.reference_code,
                event_name: this.event.name,
                quote_id: this.selected_quote.id ?? 0,
                paid_by: this.selected_invitee.id,
                payment_type_id: this.selected_payment_type.id,
                payment_gateway: this.selected_payment_gateway,
            }
            console.log(payload)
            const result = await this.classes.invitees.markInviteeAsPaid(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.closeMarkInviteeAsPaidDialog()
                this.$emit('refresh')
            }
        },
        openMarkInviteesAsPaidDialog() {
            this.mark_invitees_as_paid_dialog = true
        },
        closeMarkInviteesAsPaidDialog() {
            this.mark_invitees_as_paid_dialog = false
            this.selected_quote = null
            this.reference_code == null
        },
        async markInviteesAsPaid() {
            if (this.selected_quote == null || this.reference_code == null) {
                this.$toast.error('Please fill out all fields')
                return
            }
            let payload = {
                company_id: this.get_selected_company.id,
                event_id: this.event.id,
                invitees: this.checked_invitees,
                quote_id: this.selected_quote.id
            }
            const result = await this.classes.invitees.markInviteesAsPaid(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.closeMarkInviteesAsPaidDialog()
                this.$emit('refresh')
            }
        },
        checkAllInvitees() {
            this.checked_all_invitees = !this.checked_all_invitees
            if (this.checked_all_invitees) {
                this.event.invitees.forEach(invitee => {
                    let index = this.checked_invitees.findIndex((items) => items.id == invitee.id)
                    if (index === -1 && !invitee.paid) {
                        this.checked_invitees.push(invitee)
                    }
                })
            }
            else {
                this.checked_invitees = []
            }
        },
        checkInvitee(item) {
            let index = this.checked_invitees.findIndex((items) => items.id == item.id)
            if (index === -1) {
                this.checked_invitees.push(item)
            }
            else {
                this.checked_invitees.splice(index, 1)
            }
        },
        openNotesDialog() {
            this.notes_dialog = true
        },
        closeNotesDialog() {
            this.notes_dialog = false
        },
        async addNotes() {
            let payload = {
                id: this.event.id,
                notes: this.notes,
            }
            const result = await this.classes.events.updateEvent(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.closeNotesDialog()
                this.$emit('refresh')
            }
        },
        openUpdateInviteeDialog(item) {
            this.update_invitee_dialog = true
            this.invitee_to_update.id = item.id
            this.invitee_to_update.name = item.name
            this.invitee_to_update.email = item.email
            this.invitee_to_update.mobile = item.mobile
        },
        closeUpdateInviteeDialog() {
            this.update_invitee_dialog = false
            this.invitee_to_update = {
                id: null,
                name: null,
                email: null,
                mobile: null,
            }
        },
        async updateInvitee() {
            let payload = {
                company_id: this.get_selected_company.id,
                event_id: this.event.id,
                ...this.invitee_to_update
            }
            const result = await this.classes.invitees.updateInvitee(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.closeUpdateInviteeDialog()
                this.$emit('refresh')
            }
        },
        openDeleteInviteeDialog(item) {
            this.delete_invitee_dialog = true
            this.invitee_to_delete = item
        },
        closeDeleteInviteeDialog() {
            this.delete_invitee_dialog = false
            this.invitee_to_delete = null
            this.confirmation = null
        },
        async deleteInvitee() {
            let payload = {
                invitee_id: this.invitee_to_delete.id,
                company_id: this.get_selected_company.id,
                event_id: this.event.id,
            }
            const result = await this.classes.invitees.deleteInvitee(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.closeDeleteInviteeDialog()
                this.$emit('refresh')
            }
        },
        openDeleteInviteesDialog() {
            this.delete_invitees_dialog = true
            this.invitees_to_delete = this.checked_invitees
        },
        closeDeleteInviteesDialog() {
            this.delete_invitees_dialog = false
            this.invitees_to_delete = []
            this.confirmation = null
        },
        async deleteInvitees() {
            let payload = {
                company_id: this.get_selected_company.id,
                event_id: this.event.id,
                invitees_to_delete: this.invitees_to_delete,
            }
            const result = await this.classes.invitees.deleteInvitees(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.checked_all_invitees = false
                this.closeDeleteInviteesDialog()
                this.$emit('refresh')
            }
        },
        openAddInvitee() {
            this.add_invitee_dialog = true
        },
        closeAddInvitee() {
            this.add_invitee_dialog = false
            this.new_invitee = {
                name: null,
                email: null,
                mobile: null,
            }
        },
        async addInvitee() {
            let payload = {
                company_id: this.get_selected_company.id,
                event_id: this.event.id,
                name: this.new_invitee.name,
                email: this.new_invitee.email,
                mobile: parseInt(this.new_invitee.mobile),
                password: this.event.password,
            }
            const result = await this.classes.invitees.createInvitee(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.closeAddInvitee()
                this.$emit('refresh')
            }
            else {
                this.$toast.error(result.data.message)
            }
        },
        openAddGuests() {
            this.add_guests_dialog = true
        },
        closeAddGuests() {
            this.add_guests_dialog = false
            this.guestlist = {
                number: 0,
                quote: null,
            }
        },
        async addGuests() {
            if (this.guestlist.number == 0 || this.guestlist.quote == null) {
                this.$toast.error('You must fill in the details')
                return
            }
            let payload = {
                company_id: this.get_selected_company.id,
                event_id: this.event.id,
                number: this.guestlist.number,
                quote_id: null,
                password: this.event.password,
            }
            if (this.guestlist.quote > 0) {
                payload.quote_id = parseInt(this.guestlist.quote)
            }
            const result = await this.classes.invitees.createInvitees(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
                this.closeAddGuests()
                this.$emit('refresh')
            }
        },
        async sendOrganiserEmail() {
            let payload = {
                company_id: this.get_selected_company.id,
                lead: this.event.lead,
                event: this.event,
            }
            const result = await this.classes.events.sendOrganiserEmail(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
            }
        },
        async sendInviteeEmail(item) {
            let payload = {
                company_id: this.get_selected_company.id,
                invitee: item,
                event: this.event,
            }
            const result = await this.classes.events.sendInviteeEmail(payload)
            if (result.response == true) {
                this.$toast.success(result.message)
            }
        },
        async copyLink() {
            try {
                let link = process.env.VUE_APP_CLIENT_URL + 'sign_up/' + this.event.encrypted_id
                console.log(link)
                // await navigator.clipboard.writeText(link) // TODO: add this again once live site is using https
                alert(link)
            } catch ($e) {
                console.log($e)
                alert('Failed to copy')
            }
        },
        copyLinkV2() {
            navigator.clipboard.writeText(`${process.env.VUE_APP_CLIENT_URL}sign_up/${window.btoa(this.event.id)}?name=${this.get_selected_company.company_name}`)
        },
    }
}
</script>

<style lang="scss" scoped>
.card-format {
    border-radius: 15px !important;

    .v-data-footer {
        background-color: #E9E9E9;
        color: #7C7C7C;
        font-family: 'Work Sans';
    }

    .v-data-footer__select .v-text-field {
        background-color: white;

        .v-select__slot {
            padding-left: 10px;
        }
    }
}

.custom-small {
    color: #7C7C7C;
    font-size: 12px;
    font-weight: 600;
}

:deep(.theme--light.v-data-table tbody td) {
    height: 80px;
    border: none;
}

.label-text-color {
    color: #7C7C7C;
}

.text-proper-case {
    text-transform: capitalize;
}

.search-text-field {
    margin-top: 15px;

    .v-input__slot {
        min-height: 0 !important;
        margin-top: 8px;
    }

    .v-input__prepend-inner {
        margin-top: 5px !important;
    }
}

:deep(.custom-buttons span) {
    text-transform: none;
    font-family: Manrope;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    box-shadow: none !important;
}

:deep(.custom-buttons button) {
    padding: 8px 7px 8px 7px !important;
    border-radius: 5px;
}

.paid_row {
    background-color: #FFF1F1 !important;
}


:deep(.custom-text-field) {
    legend {
        display: none;
    }

    fieldset {
        border-color: #D3D3D3 !important;
    }

    input {
        color: #101010;
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

:deep(.v-data-table__empty-wrapper) {
    display: none;
}

:deep(.payment-type td) {
    height: 50px !important;
    border: none !important;
    font-weight: bold;
}

:deep(.small-row td) {
    height: 25px !important;
    border: none !important;
}
</style>