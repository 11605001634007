import Vue from "vue";

export default class Invitees {
    constructor() {
        this.vue = Vue
    }
    createInvitee(payload) {
        return this.vue.axios.post('admin/invitees/create_invitee', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    createInvitees(payload) {
        return this.vue.axios.post('admin/invitees/create_invitees', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    updateInvitee(payload) {
        return this.vue.axios.post(`admin/invitees/update_invitee/${payload.id}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    markInviteeAsPaid(payload) {
        return this.vue.axios.post(`admin/invitees/mark_invitee_as_paid/${payload.paid_by}`, payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    markInviteesAsPaid(payload) {
        return this.vue.axios.post('admin/invitees/mark_invitees_as_paid', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    deleteInvitee({ invitee_id, company_id, event_id }) {
        return this.vue.axios.delete(`admin/invitees/delete_invitee/${invitee_id}/${company_id}/${event_id}`).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
    deleteInvitees(payload) {
        return this.vue.axios.post('admin/invitees/delete_invitees', payload).then((data) => {
            if (data.status == 200) {
                const result = data.data
                if (result.response == true) {
                    return result
                }
            }
            else {
                return data
            }
        }).catch((err) => {
            return {
                response: false,
                data: err
            }
        })
    }
}